import Vue from "vue";
import router from "@/router";

export default {
  RESET(state) {
    state.languageList = [];
  },
  REQUEST_LANGUAGE_LIST(state) {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_LANGUAGE_LIST(state, value) {
    state.languageList = value;
    state.status = "success";
  },
  FAILURE_REQUEST_LANGUAGE_LIST(state) {
    state.status = "failure";
  },
  SUCCESS_SET_LANGUAGE(state, value) {
    Vue.i18n.set(value);
    state.key = value;
    this._vm.$config.language_key = value.toUpperCase();
    router.push({ query: { ...router.currentRoute.query, lang: value } });
  },
  FAILURE_SET_LANGUAGE(state, value) {
    Vue.i18n.set(value);
    this._vm.$config.language_key = value.toUpperCase();
    router.push({ query: { ...router.currentRoute.query, lang: value } });
    state.status = "failure";
    // @TODO implement fallback
  },
  SET_SELECTED_LANGUAGE(state, value) {
    state.selectedLanguage = value;
  },
};
