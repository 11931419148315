<template>
  <v-dialog v-model="showQrCodeReader" fullscreen>
    <qrcode-stream
      ref="qrcodestream"
      v-if="showQrCodeReader"
      @init="onInit"
      @decode="onDecode"
    ></qrcode-stream>
    <v-btn fab x-large @click="closeQrCodeReader()" class="cancelBtn">
      <v-icon>mdi-close</v-icon>
    </v-btn>
  </v-dialog>
</template>

<script>
import { QrcodeStream } from "vue-qrcode-reader";
import { mapActions, mapState } from "vuex";
export default {
  components: {
    QrcodeStream,
  },
  computed: {
    ...mapState({
      showQrCodeReader: (state) => state.showQrCodeReader,
    }),
  },
  methods: {
    ...mapActions(["closeQrCodeReader"]),
    async onInit(promise) {
      try {
        await promise;

        // on iOS this canvas must be visible for a short amount of time, for the camera api to do its setup.
        const hiddenCanvas = this.$refs.qrcodestream.$el.querySelector(
          "canvas.qrcode-stream-camera"
        );
        if (hiddenCanvas) {
          hiddenCanvas.style.display = "";
          setTimeout(() => {
            hiddenCanvas.style.display = "none";
          }, 100);
        }
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    onDecode(decodedString) {
      try {
        var url = new URL(decodedString);
      } catch {
        return;
      }

      const searchParams = new URLSearchParams(url.href);
      const location = searchParams.get("location");
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, location },
      });
      if (this.$store.state.onQrDecode) {
        // call handler provided by openQrCodeReader()
        this.$store.state.onQrDecode(url);
      }

      this.closeQrCodeReader();
    },
  },
};
</script>

<style scoped>
.cancelBtn {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 10%;
}
</style>
