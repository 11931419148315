export default () => ({
  statusInfo: "unknown",
  statusContent: "unknown",
  statusTransmit: "unknown",
  statusPurchaseFlow: "unknown",
  info: {},
  content: {},
  parentGroup: {},
  activeGroup: {},
  activeItemListData: {},
  cartItemList: [],
  cartItemIdIterator: 0,
  cartQuantity: 0,
  purchaseFlowCacheItem: {},
  showShopClosedMessage: false,
  title: "",
  description: "",
});
