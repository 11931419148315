import router from "@/router";
import find from "lodash/find";
import { Howl, Howler } from "howler";
import Vue from "vue";

export default {
  REQUEST_CHANNELS(state) {
    state.items = [];
    state.statusChannels = "fetching";
  },
  REQUEST_FAVORITES(state) {
    state.favorites = [];
    state.statusFavorites = "fetching";
  },
  SUCCESS_REQUEST_CHANNELS(state, value) {
    state.items = value;
    state.favouriteGroup = [
      {
        id: "fav_01",
        name: Vue.i18n.translate("webradio.myFavorites"),
        i18nName: Vue.i18n.translate("webradio.myFavorites"),
        radio_stations: value.favourite_radio_stations,
      },
    ];
    state.statusChannels = "success";
    state.selectedCountry =
      find(state.items.countries, { id: router.currentRoute.query.country }) ||
      state.items.countries[0];
    router.push({
      query: {
        ...router.app.$route.query,
        country: state.selectedCountry.id,
      },
    });
    state.stations = state.selectedCountry.radio_stations;
  },
  FAILURE_REQUEST_CHANNELS(state) {
    state.items = [];
    state.statusChannels = "failure";
  },
  SUCCESS_REQUEST_FAVORITES(state, value) {
    state.favorites = value;
    state.statusFavorites = "success";
  },
  FAILURE_REQUEST_FAVORITES(state) {
    state.favorites = [];
    state.statusFavorites = "failure";
  },
  SET_PLAYING_STATION(state, value) {
    state.playingStation = value;
    if (value) {
      if (state.sound) {
        state.sound.stop();
      }

      state.sound = new Howl({
        src: value.stream,
        html5: true,
      });
      state.sound.play();
    } else {
      state.sound.stop();
    }
  },
  SET_STATIONS(state, value) {
    state.stations = value;
  },
  SET_SELECTED_COUNTRY(state, value) {
    state.selectedCountry = value;
    state.stations = value.radio_stations;
    router.push({
      query: {
        ...router.app.$route.query,
        country: value.id,
      },
    });
  },
  PLAY_CHANNEL(state) {
    state.playChannel = "fetching";
  },
  SUCCESS_PLAY_CHANNEL(state) {
    state.playChannel = "success";
  },
  FAILURE_PLAY_CHANNEL(state) {
    state.playChannel = "failure";
  },
};
