import router from "@/router";
import find from "lodash/find";
export default {
  REQUEST_AIRPORTS: (state) => {
    state.statusAirports = "fetching";
    state.airports = [];
  },
  SUCCESS_REQUEST_AIRPORTS: (state, value) => {
    state.statusAirports = "success";
    state.airports = value.airports;
    state.selectedAirport =
      find(state.airports, { id: router.currentRoute.query.airport }) ||
      state.airports[0];
    router.push({
      query: {
        ...router.app.$route.query,
        airport: state.selectedAirport.id,
      },
    });
  },
  FAILURE_REQUEST_AIRPORTS: (state) => {
    state.statusAirports = "failure";
  },
  REQUEST_ARRIVALS: (state) => {
    state.statusArrivals = "fetching";
  },
  SUCCESS_REQUEST_ARRIVALS: (state, value) => {
    state.statusArrivals = "success";
    state.arrivals = value.flight_data;
    state.totalArrivalPages = Math.ceil(parseInt(value.amount) / state.limit);
  },
  FAILURE_REQUEST_ARRIVALS: (state) => {
    state.statusArrivals = "failure";
  },
  REQUEST_DEPARTURES: (state) => {
    state.statusDepartures = "fetching";
  },
  SUCCESS_REQUEST_DEPARTURES: (state, value) => {
    state.statusDepartures = "success";
    state.departures = value.flight_data;
    state.totalDeparturePages = Math.ceil(parseInt(value.amount) / state.limit);
  },
  FAILURE_REQUEST_DEPARTURES: (state) => {
    state.statusDepartures = "failure";
  },
  SET_SELECTED_AIRPORT: (state, value) => {
    state.selectedAirport = value;
    router.push({
      query: {
        ...router.app.$route.query,
        airport: value.id,
      },
    });
  },
  SET_PAGE: (state, value) => {
    state.page = parseInt(value);
    router.push({
      query: {
        ...router.app.$route.query,
        page: value,
      },
    });
  },
  SET_SEARCH_TERM: (state, value) => {
    state.searchTerm = value && value.length > 0 ? value : undefined;
    router.push({
      query: {
        ...router.app.$route.query,
        q: value,
      },
    });
  },
};
