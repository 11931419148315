import { mapMutations, mapActions, mapState } from "vuex";
import store from "@/store";
import router from "@/router";
import filter from "lodash/filter";
import detectBrowserLanguage from "detect-browser-language";

export default {
  install(Vue, options) {
    return new Vue({
      router,
      store,
      data() {
        return {
          initialLanguageLoaded: false,
        };
      },
      methods: {
        ...mapMutations({
          setLoading: "SET_LOADING",
        }),
        ...mapActions("session", [
          "init",
          "authenticate",
          "authenticateById",
          "hasWalkInContent",
        ]),
        ...mapMutations("session", {
          setInitialized: "MAIN_SET_INITIALIZED",
          setAuthenticate: "SET_AUTHENTICATE",
          setBrowserLang: "SET_BROWSER_LANG",
        }),
        ...mapActions("language", ["setLanguage"]),
      },
      computed: {
        ...mapState({
          authStatus: ({ session }) => session.status,
          onesocketStatus: ({ session }) => session.onesocketStatus,
          authResult: ({ session }) => session.authResult,
          loginData: ({ session }) => session.loginData,
          mode: ({ session }) => session.mode,
          sessionReady: ({ session }) => session.ready,
          onesocketConnected: ({ session }) => session.onesocketConnected,
          browserLang: ({ session }) => session.browserLang,
        }),
      },
      watch: {
        sessionReady(val) {
          if (val) {
            this.init();
          }
        },
        authResult(val) {
          if (
            this.mode !== "demo" &&
            this.mode !== "walkin" &&
            this.mode !== "walkin-only"
          ) {
            if (val.language) {
              this.setLanguage({
                lang_key:
                  val.language.lang_key.toLowerCase() ||
                  this.$cookies.get("lang-key") ||
                  this.browserLang ||
                  this.$route.query.lang.toLowerCase(),
              });
            }
          }
        },
        onesocketConnected(val) {
          if (!val) {
            window.location.reload();
          }
        },
        onesocketStatus(val) {
          if (val === "success") {
            this.setBrowserLang(detectBrowserLanguage().split("-")[0]);
            this.setLanguage({});
            this.authenticate({
              room: this.loginData.room,
              name: this.loginData.name,
              checkin: this.loginData.checkin,
              userId: this.loginData.userId,
            });
          }
        },
        async authStatus(val, oldVal) {
          if (val === "fetching" && !this.$store.state.session.initialized) {
            return;
          }

          if (val === "not_registered") {
            this.setLoading(false);
            this.setInitialized(false);
            await this.hasWalkInContent();
            if (oldVal === "registered") {
              this.$router.push({
                name: "login",
                query: {},
              });
            } else {
              this.$router.push({
                name: "login",
                query: {
                  ...this.$route.query,
                },
              });
            }
          } else if (val === "registered") {
            this.setLoading(false);
            this.setInitialized(true);
            // if we want to navigate to init page while authenticated we redirect to root
            if (this.$route && this.$route.name === "login") {
              this.$router.push({
                name: "home",
                query: {
                  ...this.$route.query,
                },
              });
            }
          } else if (val === "multiple_residents") {
            if (this.loginData.userId) {
              this.setLoading(false);
              const userId = parseInt(this.loginData.userId);
              if (this.authResult.checkin && this.authResult.checkin[0]) {
                this.setInitialized(true);
                await this.authenticateById(
                  filter(this.authResult.checkin[0].residents, {
                    id: userId,
                  })[0]
                );
              }
            }
            // permanent checkin + normal checkin/checkins
            else {
              this.setLoading(false);
              this.setInitialized(true);
              await this.authenticateById(this.loginData);
              // if we want to navigate to init page while authenticated we redirect to root
              if (this.$route && this.$route.name === "login") {
                this.$router.push({
                  name: "home",
                  query: {
                    ...this.$route.query,
                  },
                });
              }
            }
          }
        },
      },
    });
  },
};
