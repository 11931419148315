import { getContentpageRoute, getModuleRoute } from "./routes";
import router from "@/router";
import store from "@/store";

export function getRoute(item = {}) {
  const { Page, OnClick } = item;
  const { page = Page, onclick = OnClick } = item;

  if (page) {
    const contentpageRoute = getContentpageRoute(page);
    return router.getMatchedComponents(contentpageRoute).length > 0
      ? contentpageRoute
      : "";
  } else if (onclick) {
    const moduleRoute = getModuleRoute(onclick);
    return router.getMatchedComponents(moduleRoute).length > 0
      ? moduleRoute
      : "";
  } else {
    return "";
  }
}

export function playVideo(video) {
  // We split the media_pool path (media_pool_item_play_path) from the exported json file
  // e.g.: http://manager.iptv/media_pool/ -> /media_pool/
  video = `/media_pool/${
    video.split(router.app.$config.media_pool_item_play_path)[1]
  }`;
  store.dispatch("appplayer/setVideoUrl", {
    url: video,
  });
  store.dispatch("appplayer/setPlayState", {
    state: "play",
  });
}

export function goTo(link) {
  if (link) {
    if (router.app.$config.OPEN_LINK_NEW_TAB) {
      window.open(link, "_system");
    } else {
      window.location.href = link;
    }
  }
}
